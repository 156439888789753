



































































































































































































































































































import Vue from 'vue';
import {
  SocketTypes,
} from '@/modules/elec/Socket';
import mixins from 'vue-typed-mixins';
import { ViewMixin } from '@/mixins/ViewMixin';
import ExternalLink from '@/components/ExternalLink.vue';

export default mixins(ViewMixin).extend({
  name: 'Destination',

  metaInfo: {
    title: 'FAQ',
  },
  computed: {
    telegramLink() {
      return process.env.VUE_APP_TELEGRAM_LINK;
    },
  },

  components: {
    ExternalLink,
  },

  data() {
    const table: {[colloquial: string]: string[]} = {};

    Object.entries(SocketTypes).forEach(([socketCode, { colloquial }]) => {
      if (!(colloquial in table)) {
        table[colloquial] = [];
      }
      table[colloquial].push(socketCode);
    });

    return {
      table,
    };
  },
});
